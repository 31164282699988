import { Spin } from 'antd';
import React from 'react';

import { Loading } from '@alpine-iq/ui-library';

function Loader({ fullPage, ...props }: { fullPage?: boolean }) {
	return (
		<div
			css={`
				position: relative;

				display: flex;
				justify-content: center;
				align-items: center;

				height: ${fullPage ? '100vh' : '100%'};
				width: 100%;
			`}
		>
			<Loading {...props} />
		</div>
	);
}

export default Loader;
